import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/react-fast-marquee/dist/index.js");
;
import(/* webpackMode: "eager" */ "/app/public/assets/images/open-source/iconElephant.svg");
;
import(/* webpackMode: "eager", webpackExports: ["ClientButton"] */ "/app/src/components/Button/Button.client.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/sections/home/DPE/dpe-cities.png");
;
import(/* webpackMode: "eager" */ "/app/src/sections/home/DPE/dpe-computer.png");
;
import(/* webpackMode: "eager" */ "/app/src/sections/home/DPE/dpe-summit.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/sections/home/Hero/ImageBox.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/sections/home/OpenSource/assets/apache_logo.svg");
;
import(/* webpackMode: "eager" */ "/app/src/sections/home/OpenSource/assets/bazel_logo.svg");
;
import(/* webpackMode: "eager" */ "/app/src/sections/home/OpenSource/assets/handshake.svg");
;
import(/* webpackMode: "eager" */ "/app/src/sections/home/OpenSource/assets/kotlin_logo.png");
;
import(/* webpackMode: "eager" */ "/app/src/sections/home/OpenSource/assets/rocket.svg");
;
import(/* webpackMode: "eager" */ "/app/src/sections/home/OpenSource/assets/supporter.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/sections/home/Pillars/PillarsTabs.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/sections/home/Productivity/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/sections/home/Resources/develocity.png");
;
import(/* webpackMode: "eager" */ "/app/src/sections/home/Resources/screens.png");
;
import(/* webpackMode: "eager" */ "/app/src/sections/home/Resources/summit.jpg");
;
import(/* webpackMode: "eager" */ "/app/src/sections/home/Resources/tablet.png");
;
import(/* webpackMode: "eager" */ "/app/src/sections/home/Testimonial/netflix.jpg");
