import React from 'react';
import Link from 'next/link';
import clsx from 'clsx';
import { UrlObject as Url } from 'url';
import { ClientButton } from './Button.client';

export type VariantType = 'primary' | 'secondary' | 'tertiary' | 'ghost-dark' | 'ghost-light';
type SizeVariants = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'reactive';

export interface ButtonInterface extends React.ComponentProps<'button'> {
  children?: React.ReactNode;
  variant?: VariantType;
  href?: string | Url;
  roundedFull?: boolean;
  outline?: boolean;
  size?: SizeVariants;
}

const gradleGradientClasses = 'bg-gradient-to-br from-primary to-secondary';
const hoverGradleGradientClasses = 'hover:text-white hover:bg-gradient-to-br hover:from-primary hover:to-secondary';

const gradleBlueClasses = 'text-gradle-blue';
const activeGradleBlueClasses = 'active:bg-none active:text-gradle-blue';

const hoverCoreBlueGradientClasses = 'hover:bg-gradient-to-tr hover:from-core-blue hover:to-light-blue';
const activeCoreBlueGradientClasses = 'active:bg-gradient-to-tr active:from-core-blue active:to-light-blue';
const activeCoreBlueClasses = 'active:bg-none active:text-core-blue';

const borderBaseClasses = 'border-[1.2px] border-solid';
const borderGradleBlueClasses = 'border-[1.2px] border-solid border-gradle-blue';
const borderCoreBlueClasses = 'border-[1.2px] border-solid border-core-blue';

const stateClasses = {
  solid: {
    primary: {
      enabled: clsx(
        'text-white',
        gradleGradientClasses,
        'hover:bg-none hover:bg-gradle-green',
        activeCoreBlueGradientClasses,
      ),
      disabled: 'text-gray-4 bg-gray-5 cursor-not-allowed',
    },
    secondary: {
      enabled: clsx('text-white bg-gray-1', hoverGradleGradientClasses, 'active:bg-gray-1 active:text-light-blue'),
      disabled: 'text-gray-4 bg-gray-5 cursor-not-allowed',
    },
    tertiary: {
      enabled: clsx('bg-light-background-200 text-gradle-blue', hoverGradleGradientClasses, activeCoreBlueClasses),
      disabled: 'text-gray-4 bg-gray-5 cursor-not-allowed',
    },
    'ghost-dark': {
      enabled: clsx('text-gray-1', hoverGradleGradientClasses, activeGradleBlueClasses),
      disabled: 'text-gray-4 cursor-not-allowed',
    },
    'ghost-light': {
      enabled: clsx('text-white', hoverGradleGradientClasses, activeGradleBlueClasses),
      disabled: 'text-gray-4 cursor-not-allowed',
    },
  },
  outline: {
    primary: {
      enabled: clsx(
        borderGradleBlueClasses,
        gradleBlueClasses,
        'hover:bg-clip-padding hover:border-transparent',
        hoverGradleGradientClasses,
        activeCoreBlueClasses,
        'active:border-core-blue',
      ),
      disabled: clsx('text-gray-4 cursor-not-allowed', borderBaseClasses),
    },
    secondary: {
      enabled: clsx(
        'text-gray-1',
        borderGradleBlueClasses,
        'hover:bg-clip-padding hover:border-transparent',
        hoverGradleGradientClasses,
        'active:border-gradle-blue active:text-gray-1 active:bg-none',
      ),
      disabled: clsx('text-gray-4 cursor-not-allowed', borderBaseClasses),
    },
    tertiary: {
      enabled: clsx(
        'text-white',
        borderCoreBlueClasses,
        'hover:bg-clip-padding hover:border-transparent',
        hoverCoreBlueGradientClasses,
        activeCoreBlueClasses,
        'active:border-core-blue',
      ),
      disabled: clsx('text-gray-4 cursor-not-allowed', borderBaseClasses),
    },
    'ghost-dark': {
      enabled: clsx(
        'text-gray-1',
        borderBaseClasses,
        'hover:bg-clip-padding hover:border-transparent',
        hoverGradleGradientClasses,
        activeCoreBlueClasses,
        'active:border-gray-1',
      ),
      disabled: clsx('text-gray-4 cursor-not-allowed', borderBaseClasses),
    },
    'ghost-light': {
      enabled: clsx(
        'text-white',
        borderBaseClasses,
        'hover:bg-clip-padding hover:border-transparent',
        hoverGradleGradientClasses,
        activeCoreBlueClasses,
        'active:border-white',
      ),
      disabled: clsx('text-gray-4 cursor-not-allowed', borderBaseClasses),
    },
  },
  get: (variant: VariantType, disabled: boolean, outline: boolean) => {
    const solidSelector = outline ? 'outline' : 'solid';
    const disabledSelector = disabled ? 'disabled' : 'enabled';
    return stateClasses[solidSelector][variant][disabledSelector];
  },
};

const formClasses = {
  icon: {
    base: 'rounded-full flex-shrink-0',
    reactive: 'size-11 md:size-12 lg:size-13',
    xs: 'size-10',
    sm: 'size-11',
    md: 'size-12',
    lg: 'size-13',
    xl: 'size-13',
  },
  normal: {
    base: 'rounded-lg',
    reactive: 'px-5 py-[0.6875rem] md:py-[0.8125rem] lg:py-[0.84375rem] xl:py-3 lg:px-6 xl:px-6',
    xs: '',
    sm: 'px-5 py-[0.6875rem]',
    md: 'px-5 py-[0.8125rem]',
    lg: 'px-6 py-[0.84375rem]',
    xl: 'px-6 py-3',
  },
  get: (roundedFull: boolean, size: SizeVariants) => {
    const buttonClasses = formClasses[roundedFull ? 'icon' : 'normal'];
    return clsx(buttonClasses.base, buttonClasses[size]);
  },
};

const contentClasses = 'inline-flex items-center justify-center whitespace-nowrap text-body';

export default function Button({
  disabled = false,
  variant = 'primary',
  className,
  children,
  type,
  href,
  roundedFull = false,
  outline = false,
  size = 'reactive',
  onClick,
  ...props
}: ButtonInterface) {
  const buttonClasses = clsx(
    stateClasses.get(variant, disabled, outline),
    formClasses.get(roundedFull, size),
    contentClasses,
    className,
  );

  if (href != null && !disabled) {
    return (
      <Link href={href} className={buttonClasses}>
        {children}
      </Link>
    );
  }

  const disabledProps = disabled ? { disabled: true, 'aria-disabled': true } : {};

  return (
    <ClientButton className={buttonClasses} onClick={onClick} type={type} {...disabledProps} {...props}>
      {children}
    </ClientButton>
  );
}
